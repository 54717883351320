export const typeCheckBox="checkBox";
export const typeTextArea="textArea";
export const typeSelect="select";

export const orgNoRegExp=/^(\d{2}){0,1}(\d{2})(\d{2})(\d{2})([-+]?)?((?!000)\d{3})(\d)$/g;
export const zipcodeSwedishRegExp=/^(s-|S-){0,1}[0-9]{3}\s?[0-9]{2}$/g;
export const emailRegex= /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const maxFileSize=5242880;





import {
    Container,
    Form,
    Button,
    Row,
    Col
  } from "react-bootstrap";
  
  import { useNavigate, useLocation } from "react-router-dom";
  
  
  import * as ClLang from "../ClLang"
  import RowHelper from "../../../helper/RowHelper"
  import ApiHelper from "../../../helper/ApiHelper"
import { useState,useEffect } from "react";
import StepperHelper from "../../../helper/stepperHelper/StepperHelper"
import Loader from "../../../helper/Loader"
import GetTokenHelper from "../../../helper/GetTokenHelper";

import ClRedirecting from "../clRedirecting/ClRedirecting";
  

const GetPreviewRow=(label,data)=>{
  if(!data)return;
  
  return <RowHelper label={label} value={data}></RowHelper>
}

  
  
  const ClPreview = () => {
  
    const {state} = useLocation();
    const navigate = useNavigate();

  
  const [savedState,setSavedState]=useState(state?? {values:"redirect"});
  const [hasError,setHasError]=useState(false);
  const [ok,setOk]=useState(false);
  const [attachedFiles,setAttachedFiles]=useState("");
  const [config,setConfig]=useState();
  const [loading,setLoading]=useState(false);
  const [hasAttachedFiles,setHasAttachedFiles]=useState(false);

  const [infoGetToken,setInfoGetToken]=useState();
  
  

if(savedState.values[ClLang.CaseId]===ClLang.Mail){
  var postRow1=   <RowHelper label={ClLang.PostCaseLabel} value={savedState.values[ClLang.PostId]}></RowHelper>;
  var postRow2=   <RowHelper label={ClLang.OperatorLabel} value={savedState.values[ClLang.PostOperatorsOptionName]}></RowHelper>;
  
  if(savedState.values[ClLang.PostOperatorsOptionId]===ClLang.OtherOperator){
    var postRow3=   <RowHelper label={ClLang.OperatorLabelMailOtherDisplay} value={savedState.values[ClLang.OperatorId]}></RowHelper>;
  }
  
}
else{
  if(savedState.values[ClLang.CaseId]===ClLang.CaseInternetPhone) var lekRow1=<RowHelper label={ClLang.CaseInternetLabel} value={savedState.values[ClLang.CaseInternetPhoneId]}></RowHelper>;
  var lekRow2=<RowHelper label={ClLang.OperatorLabel} value={savedState.values[ClLang.OperatorId]}></RowHelper>;
}





  useEffect(() => {
    setAttachedFiles(<>{getFilenameFromSession(ClLang.FileUploadedId1)}{getFilenameFromSession(ClLang.FileUploadedId2)}{getFilenameFromSession(ClLang.FileUploadedId3)}</>);
    
  }, []);


  
  useEffect(() => {
    fetch('/config.json?v='+ClLang.Version)
    .then(response => response.json())
    .then(data => setConfig(
      data))
  },[])


 if(hasError&&loading){
  setLoading(false);
 }

    
  if(ok){
    navigate(ClLang.ClNavThanks);
  }


  const saveToApi=(token)=>{
    ApiHelper(savedState.values,config.postToServerUrl,setHasError,setOk,token,config.AccessHeader)
  }
  

    const onSubmit=()=>{

      setLoading(true);
      savedState.values=addGuidForFilesForSubmit(savedState.values,ClLang.FileUploadedId1)
      savedState.values=addGuidForFilesForSubmit(savedState.values,ClLang.FileUploadedId2)
      savedState.values=addGuidForFilesForSubmit(savedState.values,ClLang.FileUploadedId3)

      GetTokenHelper(config.getTokenUrl,config.AccessHeader,setInfoGetToken,saveToApi,setHasError);

//console.log(JSON.stringify(savedState.values))
  };





const getFilenameFromSession=(id)=>{
  var dto=JSON.parse(sessionStorage.getItem(id));
  if(dto!=null){
    if(hasAttachedFiles==false)setHasAttachedFiles(true);
    return <div>{dto.filename}</div>;
  }
  return null;
}
const addGuidForFilesForSubmit=(values,id)=>{
  var dto=JSON.parse(sessionStorage.getItem(id));
  if(dto!=null){
    if(ClLang.FileUploadedId1==id)values={...values,...{"FileUploadedId1":dto.guid}};
    if(ClLang.FileUploadedId2==id)values={...values,...{"FileUploadedId2":dto.guid}};
    if(ClLang.FileUploadedId3==id)values={...values,...{"FileUploadedId3":dto.guid}};
  }
  return values;
}
  
  const  backToStepOne=()=>{
      navigate(ClLang.ClNavContact, {
        state: {
            ...state
        },
      });
    }


    if(loading){
      return <Loader ></Loader>
    }


    if(state==null){
      return <ClRedirecting></ClRedirecting>;
    }

   
  
      return(
        <>
            {infoGetToken}
            <StepperHelper activeStep={2}></StepperHelper>      
       
            <fieldset>
            {hasError&&ClLang.CommonError}
            <h2>{ClLang.PreviewLegend}</h2>
            <RowHelper label={ClLang.TypeOfActorLabel} value={savedState.values[ClLang.TypeOfActorId]}></RowHelper>
            <br></br>
            <RowHelper label={ClLang.CaseLabel} value={savedState.values[ClLang.CaseId]}></RowHelper>
         
            {postRow1}
            {postRow2}
            {postRow3}
            
            {lekRow1}
            {lekRow2}
          
            <RowHelper label={ClLang.ZipcodeLabel} value={savedState.values[ClLang.ZipcodeId]}></RowHelper>

            <br></br>
             <RowHelper label={ClLang.QuestionLabel} value={savedState.values[ClLang.QuestionId]}></RowHelper>
          
             <br></br>
             <br></br>
              {savedState.values[ClLang.FeedbackPTSId]!==true&&<RowHelper label={ClLang.FeedbackPTSLabel} value={"Nej"}></RowHelper>}
              {savedState.values[ClLang.FeedbackPTSId]===true&&<RowHelper label={ClLang.FeedbackPTSLabel} value={"Ja"}></RowHelper>}
              <br></br>

              {GetPreviewRow(ClLang.EmailLabel,savedState.values[ClLang.EmailId])}
              {GetPreviewRow(ClLang.PhoneLabel,savedState.values[ClLang.PhoneId])}
              {GetPreviewRow(ClLang.CompanyLabel,savedState.values[ClLang.CompanyId])}
              {GetPreviewRow(ClLang.OrgNoLabel,savedState.values[ClLang.OrgNoId])}
          
            <br></br>
            {hasAttachedFiles&&<span>
          
              Bifogade filer
              {attachedFiles}
              </span>
            }
            
            
            {hasError&&ClLang.CommonError}
            
        <br/>
        <div className="float-end">
          <Button variant="back"  onClick={backToStepOne} className="button-margin"  >
            {ClLang.ButtonPreviousDisplay}
          </Button>
    
          <Button variant="success"  onClick={onSubmit} className="button-margin" >
          {ClLang.ButtonSendDisplay}
          </Button>
      
          </div>
          </fieldset>
          </>
      );
  
  }
  export default ClPreview
  
  //{getRow("Namn (Förnamn efternamn)",state.values.firstname+" "+state.values.lastname)}
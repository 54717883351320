

const IsValidFileFormatHelper=(file)=>
{
//    const file = e.target.files[0];
switch(file.type){
  case 'image/png':
    //('image type is png');
    return true;
    break;
  case 'image/jpg':
    //('image/jpg')
    return true;
    break;
  case 'image/jpeg':
    //('image is jpeg')
    return true;
    break;
case 'application/pdf':
    return true;
    break;
case 'application/msword':
    return true;
    break;
case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    return true;
    break;
case 'application/vnd.ms-powerpoint':
    return true;
    break;
case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    return true;
    break;
case 'text/rtf':
    return true;
    break;
case 'image/tiff':
    return true;
    break;
case 'text/plain':
    return true;
    break;
case 'application/vnd.ms-excel':
    return true;
    break;
case 'text/xml':
    return true;
    break;
default:
    return false;
    // image extension is not valid
}

return 
}


/*const isValidFileUploaded=(file)=>{
    const validExtensions = ['png','jpeg','jpg']
    const fileExtension = file.type.split('/')[1]
    return validExtensions.includes(fileExtension)
  }

}*/

export default IsValidFileFormatHelper;



import { Form, Button } from 'react-bootstrap'
import * as ClLang from '../ClLang'
import SelectHelper from '../../../helper/SelectHelper'
import InputHelperBootstrap from '../../../helper/InputHelperBootstrap'
import { useState, useEffect } from 'react'
import { useForm, setValue } from 'react-hook-form'
import * as Const from '../../../common/const'
import { useNavigate, useLocation } from 'react-router-dom'
import StepperHelper from '../../../helper/stepperHelper/StepperHelper'
import FileUploaderHelper from '../../../helper/FileUploaderHelper'
import Loader from '../../../helper/Loader'
import ClassNameGenerator from '@mui/utils/ClassNameGenerator'
import ShowOperator from '../../../helper/ShowOperator'

const getState = (values, id) => {
    try {
        return values[id]
    } catch (error) {
        return ''
    }
}

const ClSelect = () => {
    const { state } = useLocation()
    const navigate = useNavigate()

    const [preventSubmit, setPreventSubmit] = useState(true)
    const [showErrorSelect, setShowErrorSelect] = useState('0')

    const [typeOfActor, setTypeOfActor] = useState('')
    const [typOfCase, setCase] = useState('')
    const [post, setPost] = useState('')
    const [feedbackChk, setFeedbackChk] = useState(getState(state ? state.values : false, ClLang.FeedbackPTSId) == true)

    //const  [loading,setLoading] = useState(false);
    const [caseInternetPhone, setCaseInternetPhone] = useState('')
    const [postOperator, setPostOperator] = useState('')
    const [postOperatorName, setPostOperatorName] = useState('')
    const [reload, setReload] = useState('')

    const {
        unregister,
        setValue,
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm()

    useEffect(() => {
        if (state != null) {
            setTypeOfActor(getState(state.values, ClLang.TypeOfActorId))
            setCase(getState(state.values, ClLang.CaseId))
            setPost(getState(state.values, ClLang.PostId))
            //setValue(getState(state.values,ClLang.FeedbackPTSId));

            setCaseInternetPhone(getState(state.values, ClLang.CaseInternetPhoneId))
            setPostOperator(getState(state.values, ClLang.PostOperatorsOptionId))
            setPostOperatorName(getState(state.values, ClLang.PostOperatorsOptionName))

            setValue(ClLang.PostOperatorsOptionId, getState(state.values, ClLang.PostOperatorsOptionId))
            setValue(ClLang.PostOperatorsOptionName, getState(state.values, ClLang.PostOperatorsOptionName))
            setValue(ClLang.ZipcodeId, getState(state.values, ClLang.ZipcodeId))
            setValue(ClLang.QuestionId, getState(state.values, ClLang.QuestionId))

            setValue(ClLang.PhoneId, getState(state.values, ClLang.PhoneId))
            setValue(ClLang.EmailId, getState(state.values, ClLang.EmailId))
            setValue(ClLang.CompanyId, getState(state.values, ClLang.CompanyId))
            setValue(ClLang.OrgNoId, getState(state.values, ClLang.OrgNoId))
        }
        // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, [])

    var showOperator = ShowOperator({ typOfCase, post, caseInternetPhone })

    if (typOfCase !== ClLang.Mail) {
        var showOtherOperator = (
            <InputHelperBootstrap
                register={register(ClLang.OperatorId, { required: true })}
                error={errors[ClLang.OperatorId]}
                id={ClLang.OperatorId}
                label={ClLang.OperatorLabel}
                errorRequiredText={ClLang.OperatorError}
            ></InputHelperBootstrap>
        )
    }

    if (typeOfActor !== '') {
        var caseSelect = (
            <SelectHelper
                preventSubmitFunc={setPreventSubmit}
                alwaysShowError={showErrorSelect}
                label={ClLang.CaseLabel}
                errorMessage={ClLang.CaseError}
                chooseText={ClLang.ChooseLabel}
                validOptions={ClLang.CaseValidOptions}
                id={ClLang.CaseId}
                callBackFunc={setCase}
            ></SelectHelper>
        )
    }
    if (typOfCase === ClLang.Mail) {
        var selectPost = (
            <SelectHelper
                preventSubmitFunc={setPreventSubmit}
                alwaysShowError={showErrorSelect}
                label={ClLang.PostCaseLabel}
                errorMessage={ClLang.PostCaseError}
                chooseText={ClLang.ChooseLabel}
                validOptions={ClLang.PostValidOptions}
                id={ClLang.PostId}
                callBackFunc={setPost}
            ></SelectHelper>
        )
        var selectPostOperator = (
            <SelectHelper
                preventSubmitFunc={setPreventSubmit}
                alwaysShowError={showErrorSelect}
                label={ClLang.OperatorLabel}
                errorMessage={ClLang.ChooseError}
                chooseText={ClLang.Choose}
                validOptions={ClLang.PostOperatorsOptionsWithValue}
                isIdAndTextList={true}
                id={ClLang.PostOperatorsOptionId}
                callBackFunc={setPostOperator}
            ></SelectHelper>
        )

        var s = (
            <InputHelperBootstrap
                register={register(ClLang.OperatorId, { required: false })}
                error={errors[ClLang.OperatorId]}
                id={ClLang.OperatorId}
                label={ClLang.OperatorLabelMailOtherDisplay}
                errorRequiredText={ClLang.OperatorError}
            ></InputHelperBootstrap>
        )
        if (postOperator === ClLang.OtherOperator) {
            var showOtherOperator = (
                <InputHelperBootstrap
                    register={register(ClLang.OperatorId, { required: true })}
                    error={errors[ClLang.OperatorId]}
                    id={ClLang.OperatorId}
                    label={ClLang.OperatorLabelMailOtherDisplay}
                    errorRequiredText={ClLang.OperatorError}
                ></InputHelperBootstrap>
            )
        }
    } else if (typOfCase === ClLang.CaseInternetPhone) {
        var selectCaseInternetPhone = (
            <SelectHelper
                preventSubmitFunc={setPreventSubmit}
                alwaysShowError={showErrorSelect}
                label={ClLang.CaseInternetLabel}
                errorMessage={ClLang.CaseInternetPhoneError}
                chooseText={ClLang.Choose}
                validOptions={ClLang.CaseInternetPhoneOptions}
                id={ClLang.CaseInternetPhoneId}
                callBackFunc={setCaseInternetPhone}
            ></SelectHelper>
        )
    }

    const onSubmit = values => {
        if (preventSubmit) {
            setShowErrorSelect('1')
            return
        }

        if (selectPost) {
            if (!post) {
                setShowErrorSelect('1')
                return
            }
            if (!postOperator) {
                setShowErrorSelect('1')
                return
            }
        }

        values[ClLang.TypeOfActorId] = typeOfActor
        values[ClLang.CaseId] = typOfCase
        values[ClLang.PostId] = post
        values[ClLang.FeedbackPTSId] = feedbackChk
        values[ClLang.PostOperatorsOptionId] = postOperator
        values[ClLang.PostOperatorsOptionName] = ClLang.PostOperatorsOptionsWithValue.find(
            x => x.id == postOperator
        )?.text
        values[ClLang.CaseInternetPhoneId] = caseInternetPhone

        navigate(ClLang.ClNavContact, {
            state: {
                values,
            },
        })
        //alert(JSON.stringify(values));
    }

    /*if(loading){
      return <Loader ></Loader>
    }*/

    return (
        <>
            <StepperHelper activeStep={0} infoRow1={ClLang.infoStepperRow1Step1}></StepperHelper>

            <Form onSubmit={handleSubmit(onSubmit)}>
                <fieldset>
                    <h2>{ClLang.LegendClSelectH2}</h2>

                    <SelectHelper
                        preventSubmitFunc={setPreventSubmit}
                        alwaysShowError={showErrorSelect}
                        label={ClLang.TypeOfActorLabel}
                        errorMessage={ClLang.TypeOfActorError}
                        chooseText={ClLang.TypeOfActorError}
                        validOptions={ClLang.TypeOfActorValidOptions}
                        id={ClLang.TypeOfActorId}
                        callBackFunc={setTypeOfActor}
                    ></SelectHelper>

                    {caseSelect}
                    {selectPost}
                    {selectPostOperator}
                    {selectCaseInternetPhone}

                    {showOperator && (
                        <div>
                            {showOtherOperator}
                            <InputHelperBootstrap
                                register={register(ClLang.ZipcodeId, {
                                    required: true,
                                    pattern: Const.zipcodeSwedishRegExp,
                                })}
                                error={errors[ClLang.ZipcodeId]}
                                id={ClLang.ZipcodeId}
                                label={ClLang.ZipcodeLabel}
                                placeholder={ClLang.ZipcodeLabel}
                                errorRequiredText={ClLang.ZipcodeError}
                                errorPatternText={ClLang.ZipcodeError}
                            ></InputHelperBootstrap>
                            <InputHelperBootstrap
                                register={register(ClLang.QuestionId, { required: true })}
                                error={errors[ClLang.QuestionId]}
                                id={ClLang.QuestionId}
                                label={ClLang.QuestionLabel}
                                placeholder={ClLang.QuestionLabel}
                                errorRequiredText={ClLang.QuestionError}
                                type={Const.typeTextArea}
                                rows={3}
                            ></InputHelperBootstrap>

                            <label className="form-label">{ClLang.AttachmentHeadline}</label>
                            <p className="colorPtsBlue">{ClLang.FileInfo}</p>
                            <FileUploaderHelper
                                sessionStorageId={ClLang.FileUploadedId3}
                                setReload={setReload}
                            ></FileUploaderHelper>
                            <FileUploaderHelper
                                sessionStorageId={ClLang.FileUploadedId2}
                                setReload={setReload}
                            ></FileUploaderHelper>
                            <FileUploaderHelper
                                sessionStorageId={ClLang.FileUploadedId1}
                                setReload={setReload}
                            ></FileUploaderHelper>
                        </div>
                    )}
                </fieldset>

                <fieldset>
                    <div className="float-end">
                        {showOperator && (
                            <Button variant="forward" type="submit" className="button-margin">
                                {ClLang.ButtonNextDisplay}
                            </Button>
                        )}
                    </div>
                </fieldset>
            </Form>
        </>
    )
}

export default ClSelect

/*

if(ClLang.DisplayContactInfoForCompany===typeOfActor){
      var isCompany=true;
    }

const handlefeedbackChk=(e)=>{
      setFeedbackChk(e.target.checked);
    };

    const  backToStepOne=()=>{
      navigate(ClLang.ClStart);
    }

<Button variant="back"  onClick={backToStepOne} className="button-margin"  >
      {ClLang.ButtonPreviousDisplay}
          </Button>
  <InputHelperBootstrap register={register(ClLang.OrgNoId, {
    required: false,
    pattern: Const.orgNoRegExp})
    } error={errors[ClLang.OrgNoId]} id={ClLang.OrgNoId} label={ClLang.OrgNoLabel}  placeholder={ClLang.OrgNoLabel}  errorRequiredText={ClLang.OrgNoError} errorPatternText={ClLang.OrgNoError} ></InputHelperBootstrap>
    
    <InputHelperBootstrap register={register(ClLang.OperatorId, { required: true })} error={errors[ClLang.OperatorId]} id={ClLang.OperatorId} label={ClLang.getOperatorLabel(typOfCase)}   errorRequiredText={ClLang.OperatorError} ></InputHelperBootstrap>
    */

/*
    {showOperator&&<div>
        
      <Form.Check    placeholder={ClLang.FeedbackPTSLabel} label={ClLang.FeedbackPTSLabel} onChange={handlefeedbackChk} checked={feedbackChk}/>
      
      {feedbackChk&&
      <InputHelperBootstrap register={register(ClLang.EmailId, { required: true })} error={errors[ClLang.EmailId]} id={ClLang.EmailId} label={ClLang.EmailLabel}  placeholder={ClLang.EmailLabel} errorRequiredText={ClLang.EmailError}  ></InputHelperBootstrap>}
      {feedbackChk&&
      <InputHelperBootstrap register={register(ClLang.PhoneId, { required: false })} error={errors[ClLang.PhoneId]} id={ClLang.PhoneId} label={ClLang.PhoneLabel}  placeholder={ClLang.PhoneLabel} ></InputHelperBootstrap>}

      {feedbackChk&&isCompany&&
      <InputHelperBootstrap register={register(ClLang.CompanyId, { required: false })} error={errors[ClLang.CompanyId]} id={ClLang.ZipcodeId} label={ClLang.CompanyLabel}  placeholder={ClLang.CompanyLabel}   ></InputHelperBootstrap>}
      {feedbackChk&&isCompany&&
      <InputHelperBootstrap register={register(ClLang.OrgNoId)
        } error={errors[ClLang.OrgNoId]} id={ClLang.OrgNoId} label={ClLang.OrgNoLabel}  placeholder={ClLang.OrgNoLabel}  ></InputHelperBootstrap>}
      </div>
      
      }*/

import { useState,useEffect } from "react";
import { Button,Row,Col } from "react-bootstrap";
import * as ClLang from "../forms/cl/ClLang"
import GetTokenHelper from "./GetTokenHelper";
import IsValidFileFormatHelper from "./IsValidFileFormatHelper";
import FileSizeHelper from "./FileSizeHelper";






const FileUploaderHelper=({sessionStorageId,setReload})=>
{
  
  
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [sessionId,setSessionId]=useState(sessionStorageId);
  const [uploadedFile,setUploadedFile]=useState(null);
  const [keyForFileInput,setKeyForFileInput]=useState(sessionStorageId);
  const [config,setConfig]=useState();
  const [infoGetToken,setInfoGetToken]=useState();

  const handleFileChange = (event) => {
    var file=event.target.files[0];
    
    
    if(IsValidFileFormatHelper(file)==false){
      event.target.files=null;
      removeFilename();
      setError(ClLang.WrongFileType);
      return;
    }
    if(FileSizeHelper(file)==false){
      event.target.files=null;
      removeFilename();
      setError(ClLang.WrongFileSize)
      return;
    }

    setFile(file);

    
  }

  
  const removeFilename=()=>{
    sessionStorage.removeItem(sessionId);
    setUploadedFile("");
    setKeyForFileInput(null);
    setError("");
    setReload(Date.now().toString(36) + Math.random().toString(36).substring(2));
  }

  const updateSessionStorageForFilename=(filename,guid)=>{
    var newFileObject={filename:filename,guid:guid};
  
    sessionStorage.setItem(sessionId,JSON.stringify(newFileObject));
    setUploadedFile(<Row><Col>{filename}</Col><Col><a className="btn btn-danger" onClick={removeFilename}>Radera</a></Col></Row>);
  }

  
  


  useEffect(() => {
    var dto=JSON.parse(sessionStorage.getItem(sessionId));
    if(dto!=null){
      setUploadedFile(<Row><Col className="col-6">{dto.filename}</Col><Col><a className="btn btn-danger" onClick={removeFilename}>Radera</a></Col></Row>)
    }
  }, []);
 


  useEffect(() => {
    fetch('/config.json?v='+ClLang.Version)
    .then(response => response.json())
    .then(data => setConfig(
      data))
  },[])



const getNexFileId=()=>{
  if(!sessionStorage.getItem(ClLang.FileUploadedId1)){
    sessionStorage.setItem(ClLang.NextFileUpLoadedId,ClLang.FileUploadedId1);
    return ClLang.FileUploadedId1;
  } 
 if(!sessionStorage.getItem(ClLang.FileUploadedId2)){
  sessionStorage.setItem(ClLang.NextFileUpLoadedId,ClLang.FileUploadedId2);
  return ClLang.FileUploadedId2;
 }
 
 if(!sessionStorage.getItem(ClLang.FileUploadedId3)){
  sessionStorage.setItem(ClLang.NextFileUpLoadedId,ClLang.FileUploadedId3);
  return ClLang.FileUploadedId3;
 }
 
}


const handleUpload=()=>{
  GetTokenHelper(config.getTokenUrl,config.AccessHeader,setInfoGetToken,saveToApi,setError);

}



  const saveToApi = async (token) => {
    
    let uniqueId = Date.now().toString(36) + Math.random().toString(36).substring(2);
    
    const formData = new FormData();
    formData.append('FormFile', file);
    formData.append('Guid', uniqueId);
    setError(ClLang.SavingMessage);
    try {
      const response = await fetch(config.fileUploaderUrl, {
        method: 'POST',
       // body: JSON.stringify(body),
        headers: { "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*",     
        "Access-Token":token,
        "AccessHeader":config.AccessHeader
      
      },
        body:formData
      });
      
      if (!response.ok) {
        throw new Error('Failed to upload file');
      }
      //var result=await response.text();
      setError("");
      updateSessionStorageForFilename(file.name,uniqueId);
      setReload(uniqueId);

    } catch (error) {

      setError(ClLang.ErrorSavingFile);
      console.error(error);
    }
  }
if(uploadedFile){
  return <div spaceBetweenFieldSetLarge>{uploadedFile}<div className="spaceBetweenFieldSetLarge"></div></div>
}


if(sessionStorageId!==getNexFileId())return;



return(
  <div >
   
     <Row>
      <Col className="col-6">
        <input type="file" onChange={handleFileChange}  className="form-control" key={keyForFileInput}/>
  </Col>
  <Col>
  <Button variant="default" type="button" onClick={handleUpload} >
                Ladda upp
          </Button>
          {uploadedFile}
          </Col>
          <Col>
          {error} 
          {infoGetToken}
          </Col>
          </Row>
          <br></br>
</div>
)
}

export default FileUploaderHelper;
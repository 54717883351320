import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import * as ClLang from "../../forms/cl/ClLang";
import "./StepperHelper.css";

/* import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  activeStep: {
    backgroundColor: 'blue',
  },
}));*/


const StepperHelper=(props)=>
{
  const { activeStep, infoRow1,infoRow2 } = props;
return(
  <>
  <fieldset className='borderField spaceBetweenFieldSetLarge' aria-hidden="true">
  <h1>Frågor och synpunkter</h1>
  
  {infoRow1}
  {infoRow2}
  
    <br></br>
    
<Stepper activeStep={activeStep} alternativeLabel  >
{ClLang.StepperLabel.map((label) => (
  
  <Step key={label} 
  sx={{
     '& .MuiStepLabel-root .Mui-completed': {
      color: 'secondary.dark', // circle color (COMPLETED)
    },
    '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
      fill: 'white', // circle's number (ACTIVE)
    },
 
   /*'& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
      {
        color: 'black', // Just text label (COMPLETED)
      },
     '& .MuiStepLabel-root .Mui-active': {
      color: 'secondary.main', // circle color (ACTIVE)
    },
    '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
      {
        color: 'common.white', // Just text label (ACTIVE)
      },
    '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
      fill: 'black', // circle's number (ACTIVE)
    },*/
  }}>
    <StepLabel >{label}</StepLabel>
  </Step>
))}
</Stepper>
</fieldset>
  </>
)
}

export default StepperHelper;

//<StepLabel  StepIconProps={{style: {color: 'red'}}}>{label}</StepLabel>


import Spinner from 'react-bootstrap/Spinner';

  

const Loader=({saveText})=>
{


return(
    <>
    
    <div>

    <h1>{saveText}</h1>
    <Spinner animation="border" role="status" >
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  
          
          </div>
          
    </>

        )
}
        export default Loader;

import * as ClLang from "../ClLang"
import {
    Container,
    Form,
    Button,
    Row,
    Col
  } from "react-bootstrap";
  import { useNavigate, useLocation } from "react-router-dom";


const ClRedirecting = () => {
    
    const navigate = useNavigate();

    const  backToStepOne=()=>{
        navigate(ClLang.ClStart);
      }
  
      
  

    return(
        <>
        <fieldset>
            <legend>Finns inget att skicka in.</legend>
             
            </fieldset>
            <br></br>
            <br></br>
            <Button variant="back"  onClick={backToStepOne}  >
            Tillbaka till start
          </Button>
        </>
    )  
}

export default ClRedirecting;
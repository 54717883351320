import logo from './logo.svg';
import './App.css';

import { Footer, Header } from 'ui-components-react-package-45ds546dksw34';

// import  Header from './components/layout/header/Header'
// import  Footer from './components/layout/footer/Footer'

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { Container } from 'react-bootstrap';

import * as ClLang from './components/forms/cl/ClLang';
import ClSelect from './components/forms/cl/clSelect/ClSelect';
import ClPreview from './components/forms/cl/clPreview/ClPreview';
import ClThanks from './components/forms/cl/clThanks/ClThanks';
import ClStart from './components/forms/cl/clStart/ClStart';
import ClContact from './components/forms/cl/clContact/ClContact';

function App() {
    return (
        <Router>
            <div className="d-flex flex-column h-100" style={{ minHeight: '100vh' }}>
                {/* <div className="App"> */}
                <Header />
                <Container fluid="sm" style={{ maxWidth: '860px', marginBottom: '36px' }}>
                    {/* <Container  className="my-1"> */}

                    <Routes>
                        <Route path="/" excat element={<ClSelect />} />
                        <Route path={ClLang.ClNavSelect} excat element={<ClSelect />} />
                        <Route path={ClLang.ClNavContact} excat element={<ClContact />} />
                        <Route path={ClLang.ClNavPreview} excat element={<ClPreview />} />
                        <Route path={ClLang.ClNavThanks} excat element={<ClThanks />} />
                    </Routes>
                </Container>
                <Footer />
            </div>
        </Router>
    );
}

export default App;

/*<Route path="vhf" excat element={<Step1Vhf/> } 
/>
<Route path="/vhf/preview" excat element={<Preview/> } />*/

import * as ClLang from "../forms/cl/ClLang"

const GetTokenHelper=(url,accessHeader,infoGetTokenMessageFunc,saveToApi,setHasErrorFunc)=>
{
 // POST request using fetch inside useEffect React hook
 const requestOptions = {
    method: 'Get',
    headers: { 'Content-Type': 'application/json',
    'AccessHeader':accessHeader    
  },
  //mode: "no-cors",
  //  body: ""//JSON.stringify(formData)
};

infoGetTokenMessageFunc(ClLang.SavingMessage);
//setHasErrorFunc();
//setToken(false) ;


fetch(url, requestOptions)
    .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();

        // check for error response
        if (!response.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
             Promise.reject(error);
        }
        // setToken(await response.text());
         //setOkFunc(true);
         infoGetTokenMessageFunc("");
         saveToApi(await response.text())
         
    })
    
    
    .catch(error => {
        console.error('There was an error!', error);
        infoGetTokenMessageFunc('');
        setHasErrorFunc(ClLang.ErrorSavingFile);
    });
 
 


}

export default  GetTokenHelper;
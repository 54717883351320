import {
    Row,
    Col
  } from "react-bootstrap";


const RowHelper=({headline,label,value})=>
{
    var row="";
    if(headline==true){
        row=<Row><Col  className="text-start fw-bold">{label}</Col></Row>;
    }
    
    if(value==null||value===false)return null;
    else if(value===true)value="Ja";
    
    
    var row=<Row><Col xs={4} className="text-start boldMedium">{label}</Col>  <Col xs={8} className="text-start">{value} </Col></Row>;

return(
row
)
}

export default RowHelper



import { Form,Button } from "react-bootstrap";
import * as ClLang from "../ClLang"
import SelectHelper from "../../../helper/SelectHelper"
import InputHelperBootstrap from "../../../helper/InputHelperBootstrap"
import { useState,useEffect } from "react";
import { useForm,setValue } from "react-hook-form";
import * as Const from "../../../common/const"
import { useNavigate, useLocation } from "react-router-dom";
import StepperHelper from "../../../helper/stepperHelper/StepperHelper"
import FileUploaderHelper from "../../../helper/FileUploaderHelper";
import Loader from "../../../helper/Loader";
import ClassNameGenerator from "@mui/utils/ClassNameGenerator";
import ShowOperator from "../../../helper/ShowOperator"


const getState=(values,id)=>{

    try {
      return values[id];
    } 
    catch (error) {
      return "";
    }
    }
    

const ClContact = () => {
    
    
    const {state} = useLocation();
    const navigate = useNavigate();

    const  [savedState,setSavedState] = useState(state.values);
    const  [feedbackChk,setFeedbackChk] = useState(getState(state.values,ClLang.FeedbackPTSId)==true);
    const  [goBack,setGoBack] = useState(false);



    

    const {
        unregister,
        setValue ,
        register,
        handleSubmit,
        watch,
        formState: { errors }
      } = useForm();

      useEffect(() => {
      
        if(state!=null){
            setValue(ClLang.TypeOfActorId,getState(state.values,ClLang.TypeOfActorId));
            setValue(ClLang.CaseId,getState(state.values,ClLang.CaseId));
            setValue(ClLang.PostId,getState(state.values,ClLang.PostId));
            setValue(ClLang.FeedbackPTSId,getState(state.values,ClLang.FeedbackPTSId));

            setValue(ClLang.CaseInternetPhoneId,getState(state.values,ClLang.CaseInternetPhoneId));
            setValue(ClLang.PostOperatorsOptionId,getState(state.values,ClLang.PostOperatorsOptionId));
            setValue(ClLang.PostOperatorsOptionName,getState(state.values,ClLang.PostOperatorsOptionName));
          
          setValue(ClLang.OperatorId,getState(state.values,ClLang.OperatorId)) 
          setValue(ClLang.ZipcodeId,getState(state.values,ClLang.ZipcodeId)) 
          setValue(ClLang.QuestionId,getState(state.values,ClLang.QuestionId)) 
  
          if(feedbackChk){
          setValue(ClLang.PhoneId,getState(state.values,ClLang.PhoneId)) 
          setValue(ClLang.EmailId,getState(state.values,ClLang.EmailId)) 
          setValue(ClLang.CompanyId,getState(state.values,ClLang.CompanyId)) 
          setValue(ClLang.OrgNoId,getState(state.values,ClLang.OrgNoId)) 
        }
        if(getState(state?state.values:'',ClLang.TypeOfActorId)===ClLang.PrivatePersonDisplay){
          setValue(ClLang.CompanyId,undefined) 
          setValue(ClLang.OrgNoId,undefined) 
        }
      }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);

var emailRow;
    if(feedbackChk){

      emailRow=<InputHelperBootstrap register={register(ClLang.EmailId, { required: true,
      pattern:Const.emailRegex })} error={errors[ClLang.EmailId]} id={ClLang.EmailId} label={ClLang.EmailLabel}  placeholder={ClLang.EmailLabel} errorRequiredText={ClLang.EmailError}  errorPatternText={ClLang.EmailPatternError}></InputHelperBootstrap>;
    }
    else{
      setValue(ClLang.PhoneId,undefined) 
      setValue(ClLang.EmailId,undefined) 
      setValue(ClLang.CompanyId,undefined) 
      setValue(ClLang.OrgNoId,undefined) 
    }


    const handlefeedbackChk=(e)=>{
        setFeedbackChk(e.target.checked);
        setValue(ClLang.FeedbackPTSId,e.target.checked);
      };

      const  backToSelect=()=>{
        /*navigate(ClLang.ClNavSelect, {
            state: {
                values:{    
                ...savedState,
            }
            },
          });*/
          setGoBack(true);
      }

      
    const onSubmit = (values,event) => {

      
      
      if(goBack){
          navigate(ClLang.ClNavSelect, {
            state: {
              values,
            },
          });
        }
          else{
          navigate(ClLang.ClNavPreview, {
            state: {
              values,
            },
          });
        }
      
        
    }

 





return (
    <>
    <StepperHelper activeStep={1}></StepperHelper>      
    
        <Form onSubmit={handleSubmit(onSubmit)}>
        
        <fieldset>
        <h2>{ClLang.LegendClSelectH3}</h2>
        <div>{ClLang.infoStepperRow1Step2}</div>
          
        <Form.Check    register={register(ClLang.FeedbackPTSId)} placeholder={ClLang.FeedbackPTSLabel} label={ClLang.FeedbackPTSLabel} onChange={handlefeedbackChk} checked={feedbackChk}/>
        <br></br>
        <div   className={feedbackChk ? '' : 'd-none'}>
        {emailRow}
        <InputHelperBootstrap register={register(ClLang.PhoneId, { required: false })} error={errors[ClLang.PhoneId]} id={ClLang.PhoneId} label={ClLang.PhoneLabel}  placeholder={ClLang.PhoneLabel} ></InputHelperBootstrap>
        
        {getState(state?state.values:'',ClLang.TypeOfActorId)!==ClLang.PrivatePersonDisplay&&<div>
        <InputHelperBootstrap register={register(ClLang.CompanyId, { required: false })} error={errors[ClLang.CompanyId]} id={ClLang.ZipcodeId} label={ClLang.CompanyLabel}  placeholder={ClLang.CompanyLabel}   ></InputHelperBootstrap>
        <InputHelperBootstrap register={register(ClLang.OrgNoId)
          } error={errors[ClLang.OrgNoId]} id={ClLang.OrgNoId} label={ClLang.OrgNoLabel}  placeholder={ClLang.OrgNoLabel}  ></InputHelperBootstrap>
          </div>
        }
 </div>

 <div className="float-end">
  <Button variant="back"  onClick={backToSelect} className="button-margin" type="submit" >
      {ClLang.ButtonPreviousDisplay}
          </Button>

    <Button variant="forward" type="submit" className="button-margin" >
                {ClLang.ButtonNextDisplay}
          </Button>
          </div>
          </fieldset>
        </Form>
        
        </>
        

)
}
export default ClContact;

/*
   {feedbackChk&&
        <InputHelperBootstrap register={register(ClLang.EmailId, { required: true })} error={errors[ClLang.EmailId]} id={ClLang.EmailId} label={ClLang.EmailLabel}  placeholder={ClLang.EmailLabel} errorRequiredText={ClLang.EmailError}  ></InputHelperBootstrap>}

    {feedbackChk&&
        <InputHelperBootstrap register={register(ClLang.PhoneId, { required: false })} error={errors[ClLang.PhoneId]} id={ClLang.PhoneId} label={ClLang.PhoneLabel}  placeholder={ClLang.PhoneLabel} ></InputHelperBootstrap>}
  
        {feedbackChk&&ClLang.DisplayContactInfoForCompany===getState(state.savedState.values,ClLang.TypeOfActorId)&&
        <InputHelperBootstrap register={register(ClLang.CompanyId, { required: false })} error={errors[ClLang.CompanyId]} id={ClLang.ZipcodeId} label={ClLang.CompanyLabel}  placeholder={ClLang.CompanyLabel}   ></InputHelperBootstrap>}
        {feedbackChk&&ClLang.DisplayContactInfoForCompany===getState(state.savedState.values,ClLang.TypeOfActorId)&&
        <InputHelperBootstrap register={register(ClLang.OrgNoId)
          } error={errors[ClLang.OrgNoId]} id={ClLang.OrgNoId} label={ClLang.OrgNoLabel}  placeholder={ClLang.OrgNoLabel}  ></InputHelperBootstrap>}
        */

//navigate
export const ClNavSelect="/start";
export const ClNavContact="/contact";
export const ClNavPreview="/preview";
export const ClNavThanks="/thanks";
export const ClStart="/";


export const SwedenDefaultCountry="Sverige"

//Logicvariables
export const Other="Övrigt";
export const CaseInternetPhone="Telefoni eller internet";
export const Choose="Välj";
export const ChooseError="Välj ett alternativ";
export const OtherOperator="Annan operatör";

export const Mail="Post";

//clselect
//export const LegendClSelect="Frågor och synpunkter till PTS"
export const LegendClSelect="Frågor och synpunkter till PTS"

//cllegendContact
export const LegendClContact="Kontaktuppgifter [steg 1 av 3]";

//prevoew
export const PreviewLegend="Steg 3 - Granska och skicka in";

//legend
export const LegendOperator="Uppgifter om tillhandahållare";

export const LegendClSelectH2="Steg 1 - Beskriv ditt ärende";
export const infoStepperRow1Step1=<span><p>Välkommen till Post- och telestyrelsens (PTS) e-tjänst för frågor och synpunkter.</p><p>PTS kan inte lösa tvister för enskilda konsumenter. Kontakta i första hand din operatör eller leverantör och i andra hand konsumentrådgivare om du har klagomål.</p></span>;
export const infoStepperRow1Step2=<p>Om du vill ha återkoppling från PTS på ditt ärende behöver du ange dina kontaktuppgifter. Vi svarar i första hand via e-post.</p>;


export const LegendClSelectH3="Steg 2 – Ange kontaktuppgifter";
export const TextDescStep2="Om du vill ha återkoppling från PTS på ditt ärende behöver du ange dina kontaktuppgifter. Vi svarar i första hand via e-post.\n";



export const TypeOfActorLabel="Användare";
export const TypeOfActorError="Välj";
export const TypeOfActorId="typeOfActorId";

export const PrivatePersonDisplay="Privatperson";
export const DisplayContactInfoForCompany="Organisation";

export const TypeOfActorValidOptions = [PrivatePersonDisplay, DisplayContactInfoForCompany, Other];





export const CaseLabel="Område";
export const CaseError="Välj område";
export const CaseId="caseId";
export const CaseValidOptions = [Mail, CaseInternetPhone, 'Radio- och tv-mottagning','Kontanter och grundläggande betaltjänster',Other];

export const CaseInternetLabel="Kategori";
export const CaseInternetPhoneOptions=['Frågor om tillgång, t.ex. om täckning och bredbandsutbyggnad','Byte av teknik, t.ex. kopparnätets avveckling eller nedläggning av 2G- och 3G-näten','Avbrott och störningar','Hantering av personlig information, t.ex. kakor, hemligt nummer och lagring av uppgifter','Frågor om avtal, t.ex. bindningstid, prisändringar och att teckna avtal','Operatörsbyte, t.ex. nummerflytt och byte av internetleverantör','Användning av tjänster utomlands, t.ex. roaming','Annat']
export const CaseInternetPhoneError="Välj kategori";
export const CaseInternetPhoneId='caseInternetPhoneId';

export const PostCaseLabel="Kategori";
export const PostCaseError="Välj kategori";
export const PostError="Välj vad ärendet gäller";
export const PostId="postId";
export const PostValidOptions = ['Postutdelning','Tull/moms/avgifter','Pakettjänster','Ombudsnät (gul brevlåda, expeditionsställe, service)','Förändring i postutdelningen','Postanordning','Särskild posttjänst eller kompletterande postservice (lantbrevbärare)','Priser/porto','Kundservice/kundtjänst - Klagomål el synpunkter','Övrig'];

export const ChooseLabel="Välj";

/*export const getOperatorLabel = (data) => {
  if(data===Mail)return OperatorLabelMailOtherDisplay;
  return OperatorLekLabel;
export const OperatorLekLabel="Vilken aktör gäller det";
export const OperatorPostLabel="Vilken operatör gäller det?";

}*/
export const OperatorLabelMailOtherDisplay="Företagsnamn - annan operatör";

export const OperatorLabel="Operatör eller aktör";



export const OperatorError="Ange operatör eller aktör";
export const OperatorId='operatorId';

export const ZipcodeLabel="Postnummer";
export const ZipcodeError="Ange postnummer format t.ex. 10012";
export const ZipcodeId="zipcodeId";

export const QuestionLabel="Beskriv din fråga eller synpunkt";
export const QuestionError="Beskriv din fråga";
export const QuestionId="questionId";

export const FeedbackPTSLabel="Jag vill ha återkoppling från PTS";
export const FeedbackPTSId="feedbackPTSId";

export const PhoneLabel="Telefonnummer";
export const PhoneId="phoneId";

export const EmailLabel="E-post";
export const EmailError="Ange e-post";
export const EmailId="emailid";
export const EmailPatternError="Angivna e-post är i ogiltigt format (t.ex. info@pts.se)";

export const CompanyLabel="Organisation";
export const CompanyError="Ange organisation";
export const CompanyId="companyId";

export const OrgNoLabel="Organisationnummer";
export const OrgNoError="Ange organisationnummer t.ex. 202100-4359";
export const OrgNoId="orgNoId";

//array with files
export const FileUploadedId1="fileUploadedId1";
export const FileUploadedId2="fileUploadedId2";
export const FileUploadedId3="fileUploadedId3";

export const NextFileUpLoadedId="nextfileuploadedid";
export const AttachmentHeadline="Bilagor";
export const FileInfo="Du kan ladda upp 3 filer med maximal filstorlek 5 MB. Filformat som stöds är pdf, doc, docx, xls, xlsx, txt, rtf, ppt, pptx, jpg, jpeg, png, tif, tiff.";





//export const HttpsResultPost="https://localhost:7073/api/v1/ConsumerPost/SendEmailToConsumerSystem";


//stepper
export const StepperLabel = [
    'Ärende',
    'Kontaktuppgifter',
    'Granska och skicka',
    
  ];

  export const Version="1";

  export const SavingMessage=<div className="alert alert-success">Sparar... vänta</div>;
  export const ErrorSavingFile=<div className="alert alert-danger">Gick inte att ladda upp filen!</div>;
  export const CommonError=<div><span className="alert alert-danger">Något gick fel försök igen</span><br></br><br></br></div>;
  export const WrongFileType=<div className="alert alert-danger">Filformatet stöds ej.</div>;
  export const WrongFileSize=<div className="alert alert-danger">För stor fil max 5MB</div>;



    //button
    export const ButtonSendDisplay="Skicka";
    export const ButtonPreviousDisplay="Föregående";
    export const ButtonNextDisplay="Nästa";
    export const ButtonNewCaseDisplay="Nytt ärende";


    //postoperators
    export const PostOperatorsOptions=['B.J. Distribution','CityMail Sweden AB','Bring Mail Nordic AB','Georgsson Mail AB','Hall Media Logistik AB','Kiruna Mail AB','Lysekils Lokalpost','Mail Company Sweden AB','Mailworld Office AB',
    'Mariestads Brev & Paketservice AB','Mediakonsult i Göteborg AB','Neovici AB','NiM Distribution AB','NWT Distribution','Point Logistik Gota Media AB','PostNord Group AB','Postmästaren i Hälsingeland AB',
    'Pressens Morgontjänst KB','Prolog KB','SDR Svensk Direktreklam AB','Sundvalls Citypost AB','Svensk Hemleverans HB','Svensk Hemleverans Norr AB','Tidningstjänst AB','Västsvensk Tidningsdistribution AB','Vet ej']
    //'Pressens Morgontjänst KB','Prolog KB','SDR Svensk Direktreklam AB','Sundvalls Citypost AB','Svensk Hemleverans HB','Svensk Hemleverans Norr AB','Tidningstjänst AB','Västsvensk Tidningsdistribution AB',OtherOperator]
    export const PostOperatorsOptionId="postOperatorId";
    export const PostOperatorsOptionName="postOperatorName";

    export const PostOperatorsOptionsWithValue=
    [
      {id:'bjdistribution',text:'B.J. Distribution'},
      {id:'citymail',text:'CityMail Sweden AB'},
      {id:'bring',text:'Bring Mail Nordic AB'},
      {id:'georgsson',text:'Georgsson Mail AB'},
      {id:'hallmedia',text:'Hall Media Logistik AB'},
      {id:'kiruna',text:'Kiruna Mail AB'},
      {id:'lysekil',text:'Lysekils Lokalpost'},
      {id:'mailcompany',text:'Mail Company Sweden AB'},
      {id:'mailworld',text:'Mailworld Office AB'},
      {id:'mariestad',text:'Mariestads Brev & Paketservice AB'},
      {id:'mediakonsult',text:'Mediakonsult i Göteborg AB'},
      {id:'neovici',text:'Neovici AB'},
      {id:'nim',text:'NiM Distribution AB'},
      {id:'nwt',text:'NWT Distribution'},
      {id:'point',text:'Point Logistik Gota Media AB'},
      {id:'postnord',text:'PostNord Group AB'},
      {id:'postmaster',text:'Postmästaren i Hälsingeland AB'},
      {id:'pressens',text:'Pressens Morgontjänst KB'},
      {id:'prolog',text:'Prolog KB'},
      {id:'sdr',text:'SDR Svensk Direktreklam AB'},
      {id:'sundsvall',text:'Sundvalls Citypost AB'},
      {id:'svensk',text:'Svensk Hemleverans HB'},
      {id:'svenshemlev',text:'Svensk Hemleverans Norr AB'},
      {id:'tidning',text:'Tidningstjänst AB'},
      {id:'westsvensk',text:'Västsvensk Tidningsdistribution AB'},
      {id:'other',text:'Annan'},
      {id:'dontknow',text:'Vet ej'}]






import * as Const from "../common/const.js";

const FileSizeHelper=(file)=>
{
    if(file.size>=Const.maxFileSize)return false;
    return true;
}


export default FileSizeHelper;

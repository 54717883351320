import * as ClLang from "../ClLang"
import { useNavigate } from "react-router-dom";
import { Form,Button } from "react-bootstrap";

const ClThanks = () => {

    const navigate = useNavigate();

    const onSubmit = (values) => {
        sessionStorage.clear()
        
        navigate(ClLang.ClNavSelect, {
        });
      };

return(
    <>
    <fieldset>
        <legend>Tack för din synpunkt</legend>


        <Form>
            <br></br>
        <Button variant="forward"  onClick={onSubmit} className="buttonPaddingSecondButton" >
             {ClLang.ButtonNewCaseDisplay}
          </Button>
          </Form>
    </fieldset>


    
    </>

)
}

export default ClThanks
import * as React from "react";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  Dropdown,
  DropdownButton
  // InputGroup,
  // FormControl
} from "react-bootstrap";
import "../../css/common.css";


import * as Const from "../common/const"

const  errorText=(error,errorRequiredText,errorPatternText)=>{
  
  var result="";
  if(typeof errorRequiredText !== "undefined"){
    if(error?.type === "required"){
     result=<p>{errorRequiredText}</p>
  }
  }
  if(typeof errorPatternText !== "undefined"){
    if(error?.type === "pattern"){
      result=<p>{errorPatternText}</p>
    }
  }
  
  
  return result;
}

const InputHelperBootstrap = ({id,placeholder,label,register,error,type,errorRequiredText,errorPatternText,labelCheckBox,defaultValue,rows}) => {

  var formControl;
  var formLabel;
  var required;



if(typeof errorRequiredText!=="undefined"){
  required=<span className="StarRed">*</span>
}

  
  if(typeof type === "undefined"){
    type="text";
   formControl=<Form.Control  type={type}    {...register} defaultValue={defaultValue}  />
  }
  else if(type===Const.typeCheckBox){
    formControl=<Form.Check  type={type}   label={labelCheckBox}  {...register} />
  }
  else if(type==Const.typeTextArea){
    formControl=<Form.Control   as="textarea"    {...register} defaultValue={defaultValue} rows={rows}  />
  }
  else if(type===Const.typeSelect){
    formControl=  <DropdownButton id="dropdown-basic-button" title="Välj fartygsklass" {...register} >     
    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
</DropdownButton>
  
  }

  if(typeof label !== "undefined"){
    formLabel=<Form.Label >{label} {required} </Form.Label>;
  }
  
    return (

<Form.Group className="mb-3" controlId={id}>
{formLabel}


{formControl}

{error && (
  <Form.Text className="text-danger">
    {errorText(error,errorRequiredText,errorPatternText)}
  </Form.Text>
)}
</Form.Group>
    );
}

//{error.message}
/*{error?.type === "required" && errorText(error,errorRequiredText,errorPatternText)}
    {error?.type === "pattern" && <p>patterm</p>}*/


export default  InputHelperBootstrap;


const ApiHelper=(formData,url,setHasErrorFunc,setOkFunc,token,accessHeader)=>
{
 // POST request using fetch inside useEffect React hook
 const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json',
    "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*",
        "Access-Token":token,
        "AccessHeader":accessHeader      
  },
    body: JSON.stringify(formData)
};
fetch(url, requestOptions)
    .then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();

        // check for error response
        if (!response.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        setHasErrorFunc(false);
        setOkFunc(true);

        
    })
    .catch(error => {
        setHasErrorFunc(true);
        console.error('There was an error!', error);
    });



}

export default ApiHelper
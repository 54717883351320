import { useState, useEffect, useRef } from 'react';
import {
    Container,
    Form,
    Button,
    Row,
    Col,
    Dropdown,
    DropdownButton,
    // InputGroup,
    // FormControl
} from 'react-bootstrap';

const SelectHelper = ({
    preventSubmitFunc,
    alwaysShowError,
    label,
    errorMessage,
    chooseText,
    validOptions,
    id,
    isIdAndTextList = false,
    callBackFunc,
}) => {
    const [country, setCountry] = useState('');
    const [error, setError] = useState('');
    const [touched, setTouched] = useState('0');

    const [showError, setShowError] = useState(alwaysShowError);
    var required;
    if (typeof errorMessage !== 'undefined') {
        required = <span className="StarRed">*</span>;
    }

    const handleOnChange = e => {
        setCountry(e.target.value);
        setTouched('1');
        sessionStorage.setItem(id, e.target.value);

        if (typeof callBackFunc !== 'undefined') {
            callBackFunc(e.target.value);
        }

        console.log('Changed', e);
        // kollar om listan innehåhåller id och text
    };
    // Use useEffect to validate the selected value when it changes
    useEffect(() => {
        var value = sessionStorage.getItem(id);
        if (value != null) setCountry(value);

        console.log(country);
        console.log('isidandtextlist', isIdAndTextList);
        var checkIdList = false;
        if (isIdAndTextList && validOptions.some(x => x.id === country)) {
            console.log('xxxxx');
            checkIdList = true;
        }
        console.log('checkidlist', checkIdList);
        if (!validOptions.includes(country) && !checkIdList) {
            if (alwaysShowError == '1') {
                setError(errorMessage);
            }
            preventSubmitFunc(true);

            /*if(touched=="1"){
       setError(errorMessage);
       preventSubmitFunc(true)
    }*/
        } else {
            setError('');
            preventSubmitFunc(false);
        }
    }, [country, validOptions, touched, errorMessage, alwaysShowError, preventSubmitFunc, id]);

    return (
        <>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>
                    {label} {required}
                </Form.Label>

                <select className="form-select" value={country} onChange={handleOnChange} id={id}>
                    <option value="">{chooseText}</option>
                    {validOptions.map(option =>
                        isIdAndTextList ? (
                            <option key={option.id} value={option.id}>
                                {option.text}
                            </option>
                        ) : (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        )
                    )}
                </select>

                {error && <p className="text-danger form-text">{error}</p>}
            </Form.Group>
        </>
    );
};
export default SelectHelper;

//(e) => (setCountry(e.target.value)  )
